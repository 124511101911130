import { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import './styles/main-styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faSquareInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Button, Modal } from 'antd';



function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [notFill, setNotFill] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    pauseOnHover: false,
    lazyLoad: true,
  };

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    pauseOnHover: false,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  
  const formNotFill = () => {
    setNotFill(true)
  }


  useEffect(() => {
    AOS.init({
      disable: false, 
      // startEvent: 'DOMContentLoaded', 
      // initClassName: 'aos-init', 
      // animatedClassName: 'aos-animate', 
      // useClassNames: false, 
      // disableMutationObserver: false, 
      // debounceDelay: 50, 
      // throttleDelay: 99, 
  
  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      // offset: 120, 
      // delay: 0, 
      // duration: 400, 
      // easing: 'ease', 
      // once: false, 
      // mirror: false, 
      // anchorPlacement: 'top-bottom',
    });
  },[])

  return (
    <div className="App">
      <div className='header'>
        <div className='header_top_bar'>
          <div className='left'>
            <div className='logo'>
              <h1>Reno interiors</h1>
            </div>
            <div className='menu'>
              {/* <ul>
                <li>About Us</li>
                <li>Contact Us</li>
              </ul> */}
            </div>
          </div>
          <div className='right'>
            <div className='social_icon_set'>
              <ul>
                <li><FontAwesomeIcon icon={faFacebook} /></li>
                <li><FontAwesomeIcon icon={faSquareInstagram} /></li>
                <li><FontAwesomeIcon icon={faYoutube} /></li>
              </ul>
            </div>
            <div className='contact_nu'>
              <FontAwesomeIcon icon={faPhone} /> <p>+1-917-560-1378</p>
            </div>
          </div>
        </div>
      </div>
      <div className='home_full_banner'>
          <Slider {...settings}>
            <div className='banner_img'>
              <img src='/img/banner01.jpg' alt="" />
            </div>
            <div className='banner_img'>
              <img src='/img/banner02.jpg' alt="" />
            </div>
            {/* <div className='banner_img'>
              <img src='/img/banner03.jpg' alt="" />
            </div>
            <div className='banner_img'>
              <img src='/img/banner04.jpg' alt="" />
            </div> */}
          </Slider>
        </div>
        {/* <div className='mobile_menu'>
          <ul>
            <li>Home</li>
            <li>About Us</li>
            <li>Contact Us</li>
          </ul>
        </div> */}
        <div className='featured_project'>
          <h2>FEATURED PROJECTS</h2>
          <div className='featured_project_item' data-aos="fade-up" data-aos-duration="1200">
            <div className='item'>
              <img src="/img/Renointeriors-2.jpg" alt=''/>
              <div className='layer'>
                {/* <button onClick={showModal}>View Project</button> */}
              </div>
            </div>
            <div className='item'>
              <img src="/img/Renointeriors-3.jpg" alt=''/>
              <div className='layer'>
                {/* <button onClick={showModal}>View Project</button> */}
              </div>
            </div>
            <div className='item'>
              <img src="/img/Renointeriors-4.jpg" alt=''/>
              <div className='layer'>
                {/* <button onClick={showModal}>View Project</button> */}
              </div>
            </div>
            <div className='item'>
              <img src="/img/Renointeriors-5.jpg" alt=''/>
              <div className='layer'>
                {/* <button onClick={showModal}>View Project</button> */}
              </div>
            </div>
            <div className='item'>
              <img src="/img/Renointeriors-6.jpg" alt=''/>
              <div className='layer'>
                {/* <button onClick={showModal}>View Project</button> */}
              </div>
            </div>
            <div className='item'>
              <img src="/img/Renointeriors-7.jpg" alt=''/>
              <div className='layer'>
                {/* <button onClick={showModal}>View Project</button> */}
              </div>
            </div>
            <div className='item'>
              <img src="/img/Renointeriors-8.jpg" alt=''/>
              <div className='layer'>
                {/* <button onClick={showModal}>View Project</button> */}
              </div>
            </div>
          </div>
        </div>
        <div className='about_us_wrapper'>
          <div className='left' data-aos="fade-up" data-aos-duration="1200">
            <h2>About Us</h2>
            <p>Welcome to Reno General Contracting Services!</p>
            <p>With two decades of unwavering commitment to excellence in the construction industry, We are  am proud to introduce ourselves as your trusted contractors. Having honed our skills in the demanding and dynamic landscape of New York City, we now excited to bring our wealth of experience and expertise to the great state of New Jersey.</p>
            <p><strong>Meet Marwan- Co-Founder and CEO</strong> </p>
            <p>Throughout my career, I have been dedicated to delivering top-quality construction solutions, always on time and within budget. My passion for creating exceptional spaces is evident in every project I undertake. From residential renovations to commercial developments, I've successfully navigated the intricacies of the industry, ensuring clients' visions become reality.</p>
            <p>What sets me apart is my unwavering commitment to communication, transparency, and client satisfaction. I understand that every project is unique, and I work closely with my clients to tailor solutions that meet their specific needs and aspirations. Whether you're looking to transform your home or embark on a commercial venture, my team and I are here to guide you every step of the way. As we embark on this new chapter in New Jersey, I look forward to building not only exceptional structures but also lasting relationships with clients, partners, and communities. Together, we'll create spaces that inspire and endure.</p>
            <p>Thank you for considering Reno Contracting Services. I invite you to get in touch with me or a member of my team to discuss how we can turn your construction dreams into reality here in the Garden State.</p>
            <p>Let's build the future together.</p>
            <p>Warm regards, <br></br>Marwan</p>
            <p></p>
            <p><strong>Thank you for considering Reno Interiors Contracting Services</strong> </p>
          </div>
          <div className='right' data-aos="fade-up" data-aos-duration="1200">
            <img src='/img/Reno interiors-1.jpg' alt=''></img>
          </div>
          
        </div>
        <div className='client_testimonials'>
          <h2>CLIENT TESTIMONIALS</h2>
          <div className='testimonials_slider'>
            <Slider {...settings1}>
              <div>
                <div className='testimonials'>
                  <h3>Jason T.</h3>
                  <p>I am thrilled to share my wonderful experience with Reno Interiors! Living in New Jersey, I was in search of a reliable and skilled team to transform the interiors of my home, and I'm so grateful I found them. Marwan is an absolute perfectionist and made sure that every detail was perfect and to my liking. I highly recommend Reno interiors to anyone looking for honest work.</p>
                </div>
              </div>
              <div>
                <div className='testimonials'>
                  <h3>Mary S.</h3>
                  <p>From the initial consultation to the final touches, the entire process was a breeze. The team at exhibited a level of professionalism and expertise that truly set them apart. They not only met but exceeded my expectations, turning my vision into a stunning reality. </p>
                </div>
              </div>
              <div>
                <div className='testimonials'>
                  <h3>Thomas D.</h3>
                  <p>The attention to detail in the renovations was impeccable. Every room was carefully designed, and the craftsmanship was top-notch. This team had a carpenter, an electrician, a painter, a plumber and a project manager to watch over them and make sure that no detail was missed. The space was not just renovated; it was transformed into a beautiful, functional, and comfortable living environment. I love my newly renovated home so much!</p>
                </div>
              </div>
              <div>
                <div className='testimonials'>
                  <h3>Rick A.</h3>
                  <p>I bought a house that needed a complete rehab. This team came in and demoed the entire home and rebuilt and redesigned everything. The had a brilliant architect on their team that designed an open layout. What truly stood out for me was the excellent communication throughout the project. The team at Reno Interiors was always approachable and responsive. They listened to my ideas and concerns, providing valuable insights and guidance. They were able to complete the project fast and on time. This is the first time a contractor/contracting company actually delivered on time. I will always use them for my renovation projects from now on.</p>
                </div>
              </div>
              <div>
                <div className='testimonials'>
                  <h3>Patrica C.</h3>
                  <p>Thank you, Thank you, Thank you! Dear Marwan and team thank you for all of your hard work. I really appreciate the dedication and care that went into making my home a place I now love spending time in. If you're in New Jersey and looking for a reliable interior renovations company, I wholeheartedly recommend Reno Interiors. They not only meet deadlines but also deliver results and that alone speak volumes. I will be referring Reno to all of my friends and family.</p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
        
        <div className='find_us_wrapper'>
          <div className='left'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387191.0360288337!2d-74.30932404622044!3d40.697539970339214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2slk!4v1695569366013!5m2!1sen!2slk" width="600" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div className='right'>
            <div className='detils'>
              <h2>Find Us</h2>
              <h3>Address</h3>
              <ul>
                <li>791 Oakwood Ln, Ridgefield, NJ</li>
              </ul>
              <h3>Contact Us</h3>
              <ul>
                <li>Phone number:  +1-917-560-1378</li>
                <li>Email: renointeriorsgc@gmail.com</li>
              </ul>
              <h3>Social</h3>
              <ul className='social'>
                <li><FontAwesomeIcon icon={faFacebook} /></li>
                <li><FontAwesomeIcon icon={faSquareInstagram} /></li>
                <li><FontAwesomeIcon icon={faYoutube} /></li>
              </ul>
            </div>
            <div className='form'>
              <h2>Request for an estimate</h2>
              <form>

              <input placeholder="Full Name" onChange={(e) => setName(e.target.value)}></input>
              <input type='email' placeholder="Email" onChange={(e) => setEmail(e.target.value)}></input>
              <input placeholder="Contact No" onChange={(e) => setContact(e.target.value)}></input>
              <textarea placeholder="Message" onChange={(e) => setMessage(e.target.value)}></textarea>
              </form>
              
              {name && email && contact && message ? <a href={`mailto:renointeriorsgc@gmail.com?subject=Request%20for%20an%20estimate&body=Name: ${name}%0A Email:${email}%0AC ontact:${contact}%0A Message:${message}%0A`}>Submit</a> : <>
              <button onClick={formNotFill}>Submit</button>
              {notFill &&  <p style={{color: "red", textAlign: "center"}}>All the input required</p>}
              </> }
              
            </div>
            
          </div>
        </div>
        <div className='footer_wrapper'>
          <h2>Reno interiors</h2>
            <p>© 2023 Reno interiors. All Rights Reserved.</p>
        </div>
        <Modal  open={isModalOpen} onOk={handleOk} onCancel={handleCancel } footer={null}>
            <h2>Project Title</h2>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            <div className='img_collaps_wrapper'>
              <div className="column">
                  <img src="/img/project01.jpg" style={{width: "100%"}} alt='' />
                  <img src="/img/project02.jpg" style={{width: "100%"}} alt=''/>
                  <img src="/img/project03.jpg" style={{width: "100%"}} alt='' />
                  <img src="/img/project01.jpg" style={{width: "100%"}} alt=''/>
              </div>
              <div class="column">
                <img src="/img/project03.jpg" style={{width: "100%"}} alt=''/>
                <img src="/img/project04.jpg" style={{width: "100%"}} alt=''/>
                <img src="/img/project02.jpg" style={{width: "100%"}} alt=''/>
              </div> 
              <div class="column">
                <img src="/img/project01.jpg" style={{width: "100%"}} alt=''/>
                <img src="/img/project03.jpg" style={{width: "100%"}} alt=''/>
                <img src="/img/project05.jpg" style={{width: "100%"}} alt=''/>
              </div> 
              <div class="column">
                <img src="/img/project02.jpg" style={{width: "100%"}} alt=''/>
                <img src="/img/project01.jpg" style={{width: "100%"}} alt=''/>
                <img src="/img/project03.jpg" style={{width: "100%"}} alt=''/>
                <img src="/img/project05.jpg" style={{width: "100%"}} alt=''/>
              </div> 
              {/* <div className='img_item'>
                <img src="/img/project01.jpg" alt=''/>
              </div>
              <div className='img_item'>
                <img src="/img/project01.jpg" alt=''/>
              </div>
              <div className='img_item'>
                <img src="/img/project04.jpg" alt=''/>
              </div>
              <div className='img_item'>
                <img src="/img/project05.jpg" alt=''/>
              </div>
              <div className='img_item'>
                <img src="/img/project01.jpg" alt=''/>
              </div>
              <div className='img_item'>
                <img src="/img/project03.jpg" alt=''/>
              </div>
              <div className='img_item'>
                <img src="/img/project01.jpg" alt=''/>
              </div>
              <div className='img_item'>
                <img src="/img/project02.jpg" alt=''/>
              </div>
              <div className='img_item'>
                <img src="/img/project01.jpg" alt=''/>
              </div>
              <div className='img_item'>
                <img src="/img/project01.jpg" alt=''/>
              </div> */}
            </div>
        </Modal>
    </div>
     
  );
}

export default App;
